import React,{useEffect, useState} from 'react'
import CommanBtn from './CommanBtn'
import Image from 'next/image'
import {getFooterData} from '../../lib/footer'
function Cta() {
    const [footerData, setFooterData] = useState<any>(null);

    useEffect(() => {
      const fetchFooterData = async () => {
        try {
          const data = await getFooterData();
          setFooterData(data);
        } catch (error) {
          console.error('Error: front footer components fetching', error);
        }
      };
  
      fetchFooterData();
    }, []);
  return (
    <section className="lightgray pt-60">
        <div className="container">
            <div className="cta_box white gd_bg" data-aos="fade" data-aos-delay="100">
                <Image className='x_shape' src='/images/x-shape.svg' width={154} height={205} alt='x shape'/>
                <div className="cta_left">

                    <h2>{footerData?.footer?.cta?.title}</h2>
                    <p>{footerData?.footer?.cta?.description}</p>
                </div>
                <div className="cta_right">
                    <CommanBtn href={footerData?.footer?.cta?.ctaButton?.url} className="my_btn" text={footerData?.footer?.cta?.ctaButton?.title} />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Cta